<template>
  <nav-header title="项目评价" :to="to"/>

  <div v-if="JSON.stringify(info) !== '{}'">
    <div class="ti-shi">
      您的真实性评价能更好的提升用户体验感
    </div>
    <template v-if="info.isPingLun === '可以评论'">
      <div class="bei-jing">
        <div class="jian-jie">
          <div class="xin-xi-list">
            <div class="xin-xi-list-title">合同名称</div>
            <div class="xin-xi-list-val">{{ info.ht_name }}</div>
          </div>
          <div class="xin-xi-list">
            <div class="xin-xi-list-title">合同编号</div>
            <div class="xin-xi-list-val">{{ info.code }}</div>
          </div>
          <div class="xin-xi-list">
            <div class="xin-xi-list-title">项目类型</div>
            <div class="xin-xi-list-val">{{ info.type_name }}</div>
          </div>
        </div>

        <div class="form-content">
          <van-form @submit="onSubmit" label-width="70px" label-align="left">
            <van-field label="项目进度">
              <template #input>
                <van-rate
                    v-model="form.jin_du"
                    color="#ffd21e"
                />
              </template>
              <template #right-icon>
                {{ _.get(selectList, [form.jin_du, 'name']) }}
              </template>
            </van-field>

            <van-field label="报告质量">
              <template #input>
                <van-rate
                    color="#ffd21e"
                    v-model="form.zhi_liang"
                />
              </template>
              <template #right-icon>
                {{ _.get(selectList, [form.zhi_liang, 'name']) }}
              </template>
            </van-field>
            <van-field label="服务态度">
              <template #input>
                <van-rate
                    color="#ffd21e"
                    v-model="form.fen_shu"
                />
              </template>
              <template #right-icon>
                {{ _.get(selectList, [form.fen_shu, 'name']) }}
              </template>
            </van-field>

            <van-field
                label="评论内容"
                v-model="form.content"
                rows="2"
                type="textarea"
                placeholder="请输入您的评论"
            />
            <div class="form-btn">
              <van-button :disabled="an_niu_zhuan_tai" round block type="primary" native-type="submit">
                提交
              </van-button>
              <div class="text-right" style="margin-top: 30px">
                <a href="javascript:" @click="lian_xi_ke_fu">在线投诉</a>
              </div>
            </div>
          </van-form>
        </div>
      </div>
    </template>
    <div v-else>
      <van-empty :description="info.isPingLun">
        <van-button :to="to" round type="primary" class="bottom-button">
          返回到我承接的项目
        </van-button>
      </van-empty>
    </div>
  </div>

  <lian-xi-ke-fu ref="keFuId"/>

</template>

<script>
import LianXiKeFu from "@/views/open/LianXiKeFu";

export default {
  name: 'PingLun',
  data() {
    return {
      setReturnPage: 'WoDeXiangMu'
    }
  },
  components: {LianXiKeFu},

}
</script>
<script setup>
import NavHeader from "@/components/top/NavHeader";
import {ref, onMounted, computed} from 'vue'
import http from "@/api/http";
import din_shi from "@/api/din_shi";
import {useRoute, useRouter, useLink} from 'vue-router'
import _ from 'lodash'
import XEUtils from "xe-utils";


const keFuId = ref(null)
const route = useRoute()
const router = useRouter()
let ti_shi_xian_tiao = ref(require('@/assets/icon/ti-shi-xian-tiao.png'))
let to = ref({
  name: _.isEmpty(route.query.runPage) ? 'WoDeXiangMu' : route.query.runPage,
  query: {typeName: 3}
})

let miao_shu = ref({})

function lian_xi_ke_fu() {
  keFuId.value.onSelect({type: '在线客服'})
}

let form = ref({
  fen_shu: 3,
  zhi_liang: 3,
  jin_du: 3,
  content: ''
})

let an_niu_zhuan_tai = computed(() => {
  return _.isUndefined(form.value.fen_shu) && _.isUndefined(form.value.zhi_liang) && _.isUndefined(form.value.jin_du)
})


const selectList = ref({
  '5': {name: '非常满意',},
  '4': {name: '较满意',},
  '3': {name: '一般'},
  '2': {name: '不满意',},
  '1': {name: '非常不满意'},
})

function onSubmit() {
  form.value.kan_ban_id = route.params.kan_ban_id
  let data = XEUtils.toStringJSON(XEUtils.toJSONString(form.value))
  data.fen_shu = XEUtils.divide(XEUtils.subtract(data.fen_shu,3),10)
  data.zhi_liang = XEUtils.divide(XEUtils.subtract(data.zhi_liang,3),10)
  data.jin_du = XEUtils.divide(XEUtils.subtract(data.jin_du,3),10)

  http.post('/gzhphp/user_xiang_mu/xiangMuPingLun', data).then(da => {
    if (da.code === 1) {
      din_shi.success(da.msg).then(da => {
        router.push(to.value)
      })
    }
  })
}

let info = ref({})

function getData() {
  http.get('/gzhphp/user_xiang_mu/isPingLun', {
    params: {kan_ban_id: route.params.kan_ban_id}
  }).then(da => {
    if (da.code === 1) {
      info.value = da.data
    }
  })
}

onMounted(() => {
  getData()
})

</script>
<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.bottom-button {
  padding-left: 50px;
  padding-right: 50px;
}

.ti-shi {
  text-align: center;
  margin: 10px 0;
  padding: 0 15px;
  color: @pei-se;

  .ti-shi-image {
    max-width: 400px;

  }
}

.xin-xi-list {
  display: flex;
  font-size: 16px;
  margin-bottom: 6px;

  .xin-xi-list-title {
    width: 80px;
  }

  .xin-xi-list-val {
    flex: 1;
    color: @gray-7;
  }
}

.xuan-xiang {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;

  a {
    color: @gray-7;
    font-size: 16px;

    &.xuan-ze {
      color: @blue;
      font-weight: bold;
    }
  }

}

.bei-jing {
  background-color: @white;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: @yin-ying;
}

.form-content {
  margin: 20px -10px 0;

}

.jian-jie {
  padding: 0 10px;
}

.fen-ge {
  height: 10px;
  background-color: @gray-3;
}
</style>
