import http from "../../api/http";
import {Toast} from 'vant'

/**


 <van-field
 :readonly="isAutoMobile"
 v-model="form.mobile"
 label="手机号码"
 placeholder="请输入您的手机号码"

 />
 <van-field
 v-model="form.code"
 label="验证码"
 placeholder="请输入验证码"
 v-if="!isAutoMobile"
 >
 <template #button>
 <a href="javascript:" @click="getCode(form.mobile)">{{ getCodeTitle }}</a>
 </template>
 </van-field>
 */
export default {
    name: 'yanZhengMa',
    data() {
        return {
            isAutoMobile: false,//true = 自动填写手机号
            daoJiShi: 60,
            ollVal: 60 //倒计时几秒
        }
    },
    methods: {
        //获取手机号
        getMobiel() {
            return new Promise((s) => {
                http.get('/gzhphp/fu_wu_shang/getUserMobile').then(da => {
                    if (da.code === 1) {
                        s(da.data.mobile)
                        if (da.data.mobile) {
                            this.isAutoMobile = true
                        } else {
                            this.isAutoMobile = false
                        }

                    }
                })
            })
            //没有手好

        },
        //获取验证码
        getCode(mobile) {

            if (this.daoJiShi !== this.ollVal) {
                return
            }
            http.get('/mobile/index/get_code', {params: {mobile: mobile}}).then(da => {
                if (da.code === 1) {
                    Toast.success(da.msg)
                    this.startJiShi()
                }
            })
        },
        startJiShi() {
            this.daoJiShi--
            let jiSHi = setInterval(() => {
                this.daoJiShi--
                if (this.daoJiShi <= 1) {
                    this.daoJiShi = this.ollVal
                    clearInterval(jiSHi)
                }

            }, 1000)
        }
    },
    computed: {
        getCodeTitle() {
            if (this.daoJiShi === this.ollVal) {
                return '获取验证码'
            }
            return `${this.daoJiShi}s后重试`

        }
    }
}
