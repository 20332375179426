<template>
  <div class="an-niu">
    <van-button native-type="submit" block>
      <slot></slot>
    </van-button>
  </div>
</template>

<script>
export default {
  name: 'alertButton'
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.an-niu {
  border-top: 15px solid @gray-1;
  margin-bottom: 5px;
}

::v-deep .van-button {
  height: 48px;
  border-width: 0;
}
</style>
